<app-main-layout
  [breadcrumbs]="isAuthenticated() ? [{ name: 'Home', url: '/' }] : undefined"
>
  <div
    class="mainContainer col-12 p-5 m-auto d-flex justify-content-between"
    *ngIf="!loading && !showGame && !loadingContent"
  >
    <div
      class="col-4 px-0 info"
      *ngIf="!loading && !showGame && !loadingContent && windowWidth <= 1040"
    >
      <div class="infoTitle">Demo info</div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center title">
          <span> Game theme </span>
        </div>

        <div class="mx-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose a theme</mat-label>
            <mat-select [formControl]="themes">
              <mat-option
                *ngFor="let theme of enabledThemes"
                [value]="theme.variableName"
                (click)="filterLangs(theme); setAvaliableCategories()"
              >
                {{ getThemeLabel(theme) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center title">
          <span> Language </span>
        </div>

        <div class="mx-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose a language</mat-label>
            <mat-select [formControl]="languages">
              <mat-option
                *ngFor="let lang of enabledLanguages"
                [value]="lang.languageCode"
                (click)="filterLevels()"
                (click)="filterLevels(lang)"
              >
                {{ getLanguageLabel(lang) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center title">
          <span> Level </span>
        </div>

        <div class="mx-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose a level</mat-label>
            <mat-select [formControl]="level">
              <mat-option
                *ngFor="let studentLevel of gameLevels"
                [value]="studentLevel.assessmentLevel"
              >
                {{ studentLevel.level }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="programCategories col-8 px-0 programContainer">
      <div
        class="card"
        *ngFor="let categories of avaliableCategories"
      >
        <div class="header col-12 px-0 d-flex">
          <div class="p-3 mx-2">
            <img
              class="cardImg"
              [src]="categories.img"
            />
          </div>
          <div class="mx-2 p-4 categoryName">
            <span class="name">
              {{ categories.name }}
            </span>
          </div>
        </div>

        <div class="categoryInfo col-12 px-3 pb-3 pt-0">
          {{ categories.description }}
        </div>

        <div class="actionButtons d-flex justify-content-center mt-auto">
          <button
            class="actionButton text-center p-2"
            (click)="startDemo(getCategoryDemoLabel(categories))"
          >
            Play
          </button>
        </div>
      </div>
    </div>

    <div
      class="col-4 px-0 info"
      *ngIf="!loading && !showGame && !loadingContent && windowWidth > 1040"
    >
      <div class="infoTitle">Demo info</div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center title">
          <span> Game theme </span>
        </div>

        <div class="mx-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose a theme</mat-label>
            <mat-select [formControl]="themes">
              <mat-option
                *ngFor="let theme of enabledThemes"
                [value]="theme.variableName"
                (click)="filterLangs(theme); setAvaliableCategories()"
              >
                {{ getThemeLabel(theme) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center title">
          <span> Language </span>
        </div>

        <div class="mx-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose a language</mat-label>
            <mat-select [formControl]="languages">
              <mat-option
                *ngFor="let lang of enabledLanguages"
                [value]="lang.languageCode"
                (click)="filterLevels()"
                (click)="filterLevels(lang)"
              >
                {{ getLanguageLabel(lang) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="keyInput m-2">
        <div class="col-4 px-0 d-flex align-items-center title">
          <span> Level </span>
        </div>

        <div class="mx-2">
          <mat-form-field appearance="outline">
            <mat-label>Choose a level</mat-label>
            <mat-select [formControl]="level">
              <mat-option
                *ngFor="let studentLevel of gameLevels"
                [value]="studentLevel.assessmentLevel"
              >
                {{ studentLevel.level }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="game-container">
    <div
      class="mx-auto d-flex justify-content-start back"
      *ngIf="showGame"
    >
      <fa-icon
        class="mr-3"
        [icon]="back"
        (click)="endDemo()"
        matTooltip="Return to main page"
        matTooltipPosition="above"
      >
      </fa-icon>
    </div>

    <app-game
      *ngIf="!loading && showGame"
      [queryData]="queryData"
      [student]="gameStudentDTO"
      [overwriteSessionDetails]="overwriteSessionDetails"
    >
    </app-game>
  </div>

  <div
    class="loading"
    *ngIf="loading && !showGame"
  >
    <div class="col-4 px-0">
      <div class="col-12 px-0 loadingText">
        <img
          [ngStyle]="{ width: '160px', padding: '10px' }"
          src="../../../../assets/img/NeurAlign text logo.png"
        />
      </div>
      <div class="col-4 px-0 m-auto">
        <mat-progress-bar
          class="readLsColors"
          mode="indeterminate"
        ></mat-progress-bar>
      </div>
    </div>
  </div>

  <div
    class="loadingContent"
    *ngIf="loadingContent"
  >
    <fa-icon
      [icon]="loadingIcon"
      [spin]="true"
    ></fa-icon>
  </div>
</app-main-layout>
