<div
  class="mainContainer col-8 px-0 mx-auto"
  *ngIf="!loadingCategories"
>
  <div class="col-12 px-0 my-3 d-flex justify-content-start">
    <div class="col-4 px-0 categoriesList">
      <div class="col-10 px-0 text-center my-2">
        <app-simple-button
          [text]="'Add new category'"
          (click)="openNewCategoryDialog(); selectedCategory = undefined"
        >
        </app-simple-button>
      </div>

      <div
        [ngClass]="{ selectedCategory: selectedCategory === category }"
        class="col-10 px-0 p-3 text-center category"
        *ngFor="let category of categories"
        (click)="
          selectedCategory = category; buildUnlockConditionsTable(category)
        "
      >
        {{ category.name }}
      </div>
    </div>

    <div
      class="categoriesInfo col-8 px-0 text-center"
      *ngIf="selectedCategory"
    >
      <div class="buttons d-flex justify-content-center my-2">
        <app-simple-button
          [text]="'Save'"
          class="mx-2"
          (click)="updateCategory()"
        >
        </app-simple-button>

        <app-simple-button
          [text]="'Delete'"
          class="mx-2"
          (click)="deleteCategory()"
        >
        </app-simple-button>
      </div>

      <div class="my-3 strongText">
        <fa-icon
          [icon]="question"
          matTooltip="Mandatory categories need to have all sessions completed for the student to complete the program"
          matTooltipPosition="above"
        ></fa-icon>
        Mandatory to complete student progress
        <mat-checkbox
          [disabled]="selectedCategory.importedFromCourse"
          [(ngModel)]="selectedCategory.mandatory"
        ></mat-checkbox>
      </div>

      <div class="my-3 strongText">
        <fa-icon
          [icon]="question"
          matTooltip="Mark true if you want this category avaliable on the demos page"
          matTooltipPosition="above"
        ></fa-icon>
        Allow category to be played as a demo
        <mat-checkbox
          [disabled]="selectedCategory.importedFromCourse"
          [(ngModel)]="selectedCategory.usedOnDemos"
        ></mat-checkbox>
      </div>

      <div
        class="firstContainer d-flex justify-content-center align-items-start"
      >
        <div class="col-6 px-0 mx-2 dataContainer">
          <div class="title">Category Icons</div>

          <div class="categoryIcon">
            <div class="mx-2">
              <img
                [ngClass]="{
                  placeHolder: imageIsPlaceHolder(selectedCategory.img)
                }"
                [src]="selectedCategory.img"
              />

              <div class="my-2 text-center">
                <app-simple-button
                  [text]="'Change avaliable icon'"
                  (click)="addLogo('img')"
                >
                </app-simple-button>
              </div>
            </div>

            <div class="mx-2">
              <img
                [ngClass]="{
                  placeHolder: imageIsPlaceHolder(selectedCategory.img)
                }"
                [src]="selectedCategory.lockedImg"
              />

              <div class="my-2 text-center">
                <app-simple-button
                  [text]="'Change locked icon'"
                  (click)="addLogo('lockedImg')"
                >
                </app-simple-button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6 px-0 mx-2 dataContainer">
          <div class="title">Category Info</div>

          <div class="info my-3 mx-auto">
            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Name </span>
              </div>

              <div class="mx-2">
                <input
                  name="name"
                  class="text-center basicInput mx-2"
                  [(ngModel)]="selectedCategory.name"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Max Sessions </span>
              </div>

              <div class="mx-2">
                <input
                  [disabled]="selectedCategory.importedFromCourse"
                  name="sessions"
                  class="text-center basicInput mx-2"
                  type="number"
                  [(ngModel)]="selectedCategory.maxSessions"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Sessions interval </span>
              </div>

              <div class="mx-2">
                <input
                  name="sessionIntervals"
                  class="text-center basicInput mx-2"
                  type="number"
                  [(ngModel)]="selectedCategory.sessionInterval"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Graph Type </span>
              </div>

              <div class="mx-2">
                <select
                  [disabled]="selectedCategory.importedFromCourse"
                  class="basicInput basicSelectable"
                  [(ngModel)]="selectedCategory.graphType"
                >
                  <option
                    class="p-2"
                    *ngFor="let graph of graphsList | keyvalue"
                  >
                    {{ graph.value }}
                  </option>
                </select>
              </div>
            </div>

            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Container </span>
              </div>

              <div class="mx-2">
                <select
                  class="basicInput basicSelectable"
                  [(ngModel)]="selectedCategory.containerType"
                >
                  <option
                    class="p-2"
                    *ngFor="let container of containerTypes | keyvalue"
                  >
                    {{ container.value }}
                  </option>
                </select>
              </div>
            </div>

            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Order </span>
              </div>

              <div class="mx-2">
                <input
                  name="sessionIntervals"
                  class="text-center basicInput mx-2"
                  type="number"
                  [(ngModel)]="selectedCategory.order"
                  autocomplete="off"
                />
              </div>
            </div>

            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Game Duration </span>
              </div>

              <div class="percentage-input mx-2">
                <input
                  [(ngModel)]="selectedCategory.gameDuration"
                  class="text-center mr-2 percentageInput"
                  type="number"
                  id="percentage"
                  min="0"
                  max="100"
                  step="1"
                />
                <span class="suffix">%</span>
              </div>
            </div>

            <div class="keyInput m-2">
              <div class="col-4 px-0 d-flex align-items-center">
                <span> Description </span>
              </div>

              <div class="mx-2">
                <textarea
                  spellcheck="false"
                  [(ngModel)]="selectedCategory.description"
                  class="basicInput basicTextarea"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="secondContainer d-flex justify-content-center align-items-start"
      >
        <div class="col-6 px-0 mx-2 dataContainer">
          <div class="col-12 px-0">
            <div class="title">Unlock Conditions</div>

            <div class="unlockConditions">
              <select
                class="basicInput basicSelectable"
                [(ngModel)]="categoryName"
                [ngClass]="{ disabledButton: filteredCategories.length === 0 }"
              >
                <option
                  (click)="resetSession()"
                  class="p-2"
                  *ngFor="let category of filteredCategories; let i = index"
                >
                  {{ category.name }}
                </option>
              </select>

              <input
                [ngClass]="{ disabledButton: filteredCategories.length === 0 }"
                limit-to-max
                [max]="getCategoryMaxSession()"
                min="0"
                name="unlockConditionSession"
                class="text-center basicInput mx-2"
                type="number"
                [(ngModel)]="categorySession"
                autocomplete="off"
              />

              <button
                [ngClass]="{ disabledButton: filteredCategories.length === 0 }"
                class="basicButton my-2"
                (click)="addNewCondition()"
              >
                Add new
              </button>
            </div>

            <div
              class="text-center my-3 warning"
              *ngIf="selectedCategory.unlockCondition.length === 0"
            >
              You dont have any unlock conditions for this category.
            </div>

            <div
              class="unlockConditionsList"
              *ngIf="selectedCategory.unlockCondition.length > 0"
            >
              <table
                mat-table
                [dataSource]="unlockConditionsDataSource"
                class="col-12 px-0"
                multiTemplateDataRows
              >
                <ng-container matColumnDef="category">
                  <th
                    class="text-center"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Category
                  </th>
                  <td
                    class="text-center"
                    mat-cell
                    *matCellDef="let condition"
                  >
                    {{ condition.name }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="session">
                  <th
                    class="text-center"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Session
                  </th>
                  <td
                    class="text-center"
                    mat-cell
                    *matCellDef="let condition"
                  >
                    {{ condition.session }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <th
                    class="text-center"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    Actions
                  </th>
                  <td
                    class="text-center"
                    mat-cell
                    *matCellDef="let condition"
                  >
                    <fa-icon
                      [icon]="remove"
                      matToolTip="Remove condition"
                      (click)="removeCondition(condition)"
                    >
                    </fa-icon>
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="conditionsColums"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: conditionsColums"
                  class="example-element-row"
                ></tr>
              </table>
            </div>
          </div>

          <div
            class="col-12 px-0 mt-4"
            *ngIf="selectedCategory.bonusPack"
          >
            <div class="title">Bonus Pack</div>

            <div class="col-10 px-3 m-auto">
              <div class="keyInput demoInputs my-2">
                <div class="col-11 px-0 demoTitle">Enable bonus pack</div>

                <div class="col px-0">
                  <mat-checkbox
                    [(ngModel)]="selectedCategory.bonusPack.enabled"
                  >
                  </mat-checkbox>
                </div>
              </div>

              <div class="keyInput demoInputs">
                <div class="col-11 px-0 demoTitle">Edit bonus pack</div>

                <div class="col px-0">
                  <fa-icon
                    [icon]="edit"
                    (click)="openBonusPackDialog()"
                  >
                  </fa-icon>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 px-0 mt-4">
            <div class="title">Avaliable Languages</div>

            <table
              mat-table
              [dataSource]="langDataSource"
              class="col-12 px-0"
              multiTemplateDataRows
            >
              <ng-container matColumnDef="name">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text-center"
                >
                  Name
                </th>
                <td
                  mat-cell
                  *matCellDef="let lang"
                >
                  {{ lang.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="code">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  class="text-center"
                >
                  Code
                </th>
                <td
                  mat-cell
                  *matCellDef="let lang"
                >
                  {{ lang.languageCode }}
                </td>
              </ng-container>

              <ng-container matColumnDef="enabled">
                <th
                  class="text-center"
                  mat-header-cell
                  *matHeaderCellDef
                >
                  Enabled
                </th>
                <td
                  mat-cell
                  *matCellDef="let lang"
                  class="text-center"
                >
                  <mat-checkbox
                    (click)="addAvaliableLanguage(lang.languageCode)"
                    [checked]="checkIfLanguageIsEnabled(lang.languageCode)"
                  >
                  </mat-checkbox>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="langDisplayedColumns"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: langDisplayedColumns"
                class="example-element-row"
              ></tr>
            </table>
          </div>
        </div>

        <div class="col-6 px-0 mx-2 dataContainer">
          <div class="title">Games List</div>
          <table
            *ngIf="!selectedCategory.importedFromCourse"
            mat-table
            [dataSource]="gamesDataSource"
            matSort
            class="col-12 px-0"
            multiTemplateDataRows
          >
            <ng-container matColumnDef="games">
              <th
                class="text-center"
                mat-header-cell
                *matHeaderCellDef
              >
                Game
              </th>
              <td
                mat-cell
                *matCellDef="let game"
                class="text-center"
              >
                {{ gamesList[game] }}
              </td>
            </ng-container>

            <ng-container matColumnDef="enabled">
              <th
                class="text-center"
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
              >
                Enabled
              </th>
              <td
                mat-cell
                *matCellDef="let game"
                class="text-center"
              >
                <mat-checkbox
                  (click)="addGameToList(game)"
                  [checked]="isGameEnabled(game)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <tr
              mat-header-row
              *matHeaderRowDef="gamesColums"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: gamesColums"
              class="example-element-row"
            ></tr>
          </table>

          <div
            class="courseImportWarning"
            *ngIf="selectedCategory.importedFromCourse"
          >
            This category is based on a LMS course and dont have games on its
            composition
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
